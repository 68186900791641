import { SpecialityGroupEntity } from '../specialty-group/speciality-group.entity';
import { ScheduleEntity, SchedulePlanEntity } from './schedule.entity';
import { UserEntity } from '../user/user.entity';
import { BuildingEntity } from '../building/building.entity';
import { ClassroomEntity } from '../building-classroom/classroom.entity';
import { CurrentLessonMeta } from './schedule-modal-type.context';
import { api } from '../app/networking/axios';

export const fetchGroups = () =>
  api
    .get<SpecialityGroupEntity[]>('/groups/', {
      params: {
        offset: 0,
        limit: 500,
      },
    })
    .then(response => response.data);

export const fetchTeachers = () =>
  api
    .get<UserEntity[]>('/users/', {
      params: {
        offset: 0,
        limit: 500,
        type: 'TEACHER',
      },
    })
    .then(response => response.data);

export const fetchBuildings = () =>
  api
    .get<BuildingEntity[]>('/buildings/', {
      params: {
        offset: 0,
        limit: 500,
      },
    })
    .then(response => response.data);

export const fetchClassrooms = () =>
  api
    .get<ClassroomEntity[]>('/buildings/0/classrooms/', {
      params: {
        offset: 0,
        limit: 500,
      },
    })
    .then(response => response.data);

export const fetchSchedule = (
  start: number,
  end: number,
  isSemester: boolean,
  isPublished: boolean,
) =>
  api
    .get<ScheduleEntity[]>('/schedule/manager/', {
      params: {
        start,
        end,
        isSemester,
        isPublished,
      },
    })
    .then(response => response.data);

export const fetchPlans = (groupId: number, date: number) =>
  api
    .get<SchedulePlanEntity[]>('/schedule/manager/plans/', {
      params: {
        groupId,
        date,
      },
    })
    .then(response => response.data);

export const saveSchedule = (
  groupId: number,
  date: number,
  sort: number,
  isPublished: boolean,
  isSemester: boolean,
  items: CurrentLessonMeta[],
) =>
  api.post('/schedule/manager/', {
    groupId,
    date,
    sort,
    isPublished,
    isSemester,

    items,
  });

export const publishSchedule = (
  start: number,
  end: number,
  buildingId?: number,
) =>
  api.post('/schedule/manager/publish', {
    start,
    end,
    ...(buildingId && { buildingId }),
  });

export const cloneScheduleSemester = (to: number, buildingId?: number) =>
  api.post('/schedule/manager/clone/semester', {
    to,
    ...(buildingId && { buildingId }),
  });

export const cloneScheduleWeek = (
  from: number,
  to: number,
  isSemester: boolean,
  isPublished: boolean,
) =>
  api.post('/schedule/manager/clone/week', {
    from,
    to,
    isSemester,
    isPublished,
  });

export const destroySchedule = (
  groupId: number,
  date: number,
  sort: number,
  isSemester: boolean,
  isPublished: boolean,
) =>
  api.delete('/schedule/manager', {
    data: {
      groupId,
      date,
      sort,
      isSemester,
      isPublished,
    },
  });
